export const NAME = 'ticket'
export const ADD = 'ADD_TICKET'
export const REMOVE = 'REMOVE_TICKET'
export const SET_FIELD = 'SET_TICKET_FIELD'
export const SET_LOCKED = 'SET_TICKET_LOCKED'
export const SET_SAVED = 'SET_TICKET_SAVED'
export const SET_SAVED_BANNER = 'SET_TICKET_SAVED_BANNER'
export const SET_LOCATION = 'SET_LOCATION'
export const RESET = 'RESET_TICKET'
export const SET_LOADING = 'SET_TICKET_LOADING'
//export const SET_PARAM = 'SET_TICKET_FIELDS';


